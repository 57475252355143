import React from 'react';
import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonIcon,
  useIonViewDidEnter,
} from '@ionic/react';
import { settings, basket, body, nutrition, barbell, home, list } from 'ionicons/icons';
import BackButton from '../components/BackButton';

const More = () => {
  useIonViewDidEnter(() => {
    document.title = 'Meer';
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Meer</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem routerLink="/">
          <IonIcon icon={home} slot="start" />
          <IonLabel>Dashboard</IonLabel>
        </IonItem>

        <IonItem routerLink="/consumptions">
          <IonIcon icon={nutrition} slot="start" />
          <IonLabel>Voeding</IonLabel>
        </IonItem>

        <IonItem routerLink="/shopping-list">
          <IonIcon icon={list} slot="start" />
          <IonLabel>Boodschappen</IonLabel>
        </IonItem>

        <IonItem routerLink="/measurements">
          <IonIcon icon={body} slot="start" />
          <IonLabel>Gewicht</IonLabel>
        </IonItem>

        <IonItem routerLink="/crypto/position-size-calculator">
          <IonIcon icon={body} slot="start" />
          <IonLabel>Crypto</IonLabel>
        </IonItem>

        <IonItem routerLink="/activities">
          <IonIcon icon={barbell} slot="start" />
          <IonLabel>Activiteiten</IonLabel>
        </IonItem>

        <IonItem routerLink="/orders">
          <IonIcon icon={basket} slot="start" />
          <IonLabel>Bestellingen</IonLabel>
        </IonItem>

        <IonItem href="/admin/dashboard">
          <IonIcon icon={settings} slot="start" />
          <IonLabel>Admin</IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default More;
