import React, { useState, useRef } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonRefresherContent,
  IonRefresher,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  useIonViewWillLeave,
  useIonViewDidEnter,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { arrowForward, chatboxEllipses } from 'ionicons/icons';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { format } from 'date-fns';
import AddButton from '../components/AddButton';
import LoadingText from '../components/LoadingText';
import ErrorText from '../components/ErrorText';
import { nl } from 'date-fns/locale';
import BackButton from '../components/BackButton';
import BMI from '../components/BMI';
import MeasurementModal, { ShowModal } from './MeasurementModal';

interface VoedingscentrumMeasurementsData {
  voedingscentrumMeasurements: VoedingscentrumMeasurementData[];
}

interface VoedingscentrumMeasurementData {
  id: string;
  createdDate: string;
  weight: string;
  length: string;
  roundedBmi: string;
  color: number;
  comment: string | null;
}

const VOEDINGSCENTRUM_MEASUREMENTS_QUERY = gql`
  query voedingscentrumMeasurementsQuery {
    voedingscentrumMeasurements(sortBy: ["desc:created_date"], page: 0, perPage: 50) {
      id
      createdDate
      weight
      length
      roundedBmi
      color
      comment
    }
  }
`;

const SYNC_VOEDINGSCENTRUM_MEASUREMENTS_MUTATION = gql`
  mutation syncVoedingscentrumMeasurementsMutation {
    syncVoedingscentrumMeasurements
  }
`;

const newMeasurement = { createdDate: new Date(), length: 188 };

const Measurements = () => {
  useIonViewDidEnter(() => {
    document.title = 'Gewicht';
  });

  const pageRef = useRef(undefined);
  const [showModal, setShowModal] = useState<ShowModal>(false);
  const [lastData, setLastData] = useState<VoedingscentrumMeasurementsData | undefined>();

  const {
    loading,
    error,
    data: currentData,
  } = useQuery<VoedingscentrumMeasurementsData>(VOEDINGSCENTRUM_MEASUREMENTS_QUERY, {
    onCompleted: setLastData,
    fetchPolicy: 'cache-and-network',
  });

  const [syncVoedingscentrumMeasurements] = useMutation<boolean>(
    SYNC_VOEDINGSCENTRUM_MEASUREMENTS_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['voedingscentrumMeasurementsQuery'],
    }
  );

  const data = loading ? lastData : currentData;

  const handleIonRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));
    const mutation = syncVoedingscentrumMeasurements();
    Promise.all([delay, mutation]).finally(event.detail.complete);
  };

  const [refresherIsEnabled, setRefresherIsEnabled] = useState(false);

  useIonViewDidEnter(() => setRefresherIsEnabled(true));
  useIonViewWillLeave(() => setRefresherIsEnabled(false));

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Gewicht</IonTitle>
          <IonButtons slot="end">
            <AddButton onClick={() => setShowModal(newMeasurement)} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" disabled={!refresherIsEnabled} onIonRefresh={handleIonRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {data && (
          <MeasurementModal pageRef={pageRef} showModal={showModal} setShowModal={setShowModal} />
        )}

        {!data && loading && <LoadingText />}
        {error && <ErrorText error={error} />}

        {data && <MeasurementsCard data={data} />}
        {data && <MeasurementsList data={data} setShowModal={setShowModal} />}
      </IonContent>
    </IonPage>
  );
};

interface MeasurementsCardProps {
  data: VoedingscentrumMeasurementsData;
}

const MeasurementsCard = React.memo(({ data }: MeasurementsCardProps) => {
  const [measurement] = data.voedingscentrumMeasurements;
  const date = new Date(measurement.createdDate);
  const formattedDate = format(date, 'PP', { locale: nl });

  return (
    <IonCard
      color="dark"
      className="ion-padding"
      routerLink={`/measurement/${measurement.id}`}
      routerDirection="none"
    >
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="auto">
            <IonCardHeader>
              <IonCardSubtitle>Gewicht</IonCardSubtitle>
              <IonCardTitle>
                {formatDecimal(measurement.weight)}{' '}
                <IonText color="medium" class="small">
                  <small>kg</small>
                </IonText>
              </IonCardTitle>
              <sub>{formattedDate}</sub>
            </IonCardHeader>
          </IonCol>
          <IonCol className="ion-text-right ion-padding">
            <IonIcon icon={arrowForward} size="large" />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
});

interface MeasurementsListProps {
  data: VoedingscentrumMeasurementsData;
  setShowModal: (showModal: ShowModal) => void;
}

const MeasurementsList = ({ data, setShowModal }: MeasurementsListProps) => (
  <IonList>
    <IonItem style={{ '--padding-end': '20px' }}>
      <IonGrid>
        <IonRow>
          <IonCol size="4">
            <strong>Datum</strong>
          </IonCol>
          <IonCol size="4" className="ion-text-right">
            <strong>
              Gewicht<span className="ion-hide-sm-down"> (kg)</span>
            </strong>
          </IonCol>
          <IonCol size="4" className="ion-text-right">
            <strong>BMI</strong>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
    {data.voedingscentrumMeasurements.map((voedingscentrumMeasurement) => (
      <MeasurementsItem
        key={voedingscentrumMeasurement.id}
        voedingscentrumMeasurement={voedingscentrumMeasurement}
        setShowModal={setShowModal}
      />
    ))}
  </IonList>
);

const formatDate = (date: string) => new Date(date).toLocaleDateString();

const formatDecimal = (number: string) =>
  parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 1 });

const DELETE_VOEDINGSCENTRUM_MEASUREMENT = gql`
  mutation deleteVoedingscentrumMeasurementMutation($voedingscentrumMeasurementId: ID!) {
    deleteVoedingscentrumMeasurement(voedingscentrumMeasurementId: $voedingscentrumMeasurementId)
  }
`;

interface MeasurementsItemProps {
  voedingscentrumMeasurement: VoedingscentrumMeasurementData;
  setShowModal: (showModal: ShowModal) => void;
}

const MeasurementsItem = React.memo(
  ({ voedingscentrumMeasurement, setShowModal }: MeasurementsItemProps) => {
    const [deleteVoedingscentrumMeasurement] = useMutation(DELETE_VOEDINGSCENTRUM_MEASUREMENT, {
      refetchQueries: ['voedingscentrumMeasurementsQuery'],
    });

    const handleDeleteClick = () =>
      deleteVoedingscentrumMeasurement({
        variables: { voedingscentrumMeasurementId: voedingscentrumMeasurement.id },
      });

    return (
      <IonItemSliding>
        <IonItem
          button
          detail={true}
          routerLink={`/measurement/${voedingscentrumMeasurement.id}`}
          routerDirection="none"
          style={{ '--padding-end': '20px' }}
        >
          <IonGrid>
            <IonRow>
              <IonCol size="4">{formatDate(voedingscentrumMeasurement.createdDate)}</IonCol>
              <IonCol
                size="4"
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              >
                {voedingscentrumMeasurement.comment && (
                  <MeasurementsCommentIcon className="ion-margin-end" />
                )}
                {formatDecimal(voedingscentrumMeasurement.weight)}
              </IonCol>
              <IonCol size="4" className="ion-text-right">
                <BMI
                  roundedBmi={voedingscentrumMeasurement.roundedBmi}
                  color={['success', 'warning', 'danger'][voedingscentrumMeasurement.color]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={handleDeleteClick}>
            Verwijder
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    );
  }
);

interface MeasurementsCommentIconProps {
  className?: string;
}

const MeasurementsCommentIcon = React.memo(({ className }: MeasurementsCommentIconProps) => (
  <IonIcon icon={chatboxEllipses} className={className} />
));

export default Measurements;
