import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  useIonViewDidEnter,
  useIonViewWillLeave,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonChip,
  IonIcon,
  IonList,
  IonItemGroup,
  IonItemDivider,
  IonThumbnail,
  IonLabel,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import LoadingText from '../components/LoadingText';
import ErrorText from '../components/ErrorText';
import formatAmount from '../utils/formatAmount';
import { basket } from 'ionicons/icons';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import BackButton from '../components/BackButton';

const ORDERS_QUERY = gql`
  query ordersQuery {
    orderCount

    orderWeeks(sortBy: ["desc:week"]) {
      week
      orders(sortBy: ["desc:ordered_at"]) {
        __typename

        ... on ThuisbezorgdOrder {
          id
          externalId
          logoUrl
          name
          orderNumber
          orderedAt
          price
          restaurantId
        }
      }
    }
  }
`;

interface OrdersQueryData {
  orderCount: number;
  orderWeeks: OrderWeekData[];
}

interface OrderWeekData {
  week: string;
  orders: OrderData[];
}

type OrderData = ThuisbezorgdOrderData;

interface ThuisbezorgdOrderData {
  __typename: 'ThuisbezorgdOrder';
  id: string;
  externalId: string;
  logoUrl: string;
  name: string;
  orderNumber: string;
  orderedAt: string;
  price: string;
  restaurantId: string;
}

const Orders = () => {
  useIonViewDidEnter(() => {
    document.title = 'Bestellingen';
  });

  const [lastData, setLastData] = useState<OrdersQueryData | undefined>();

  const {
    loading,
    error,
    data: currentData,
    refetch,
  } = useQuery<OrdersQueryData>(ORDERS_QUERY, {
    onCompleted: setLastData,
    fetchPolicy: 'cache-and-network',
  });

  const data = loading ? lastData : currentData;

  const handleIonRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));
    Promise.all([delay, refetch]).finally(event.detail.complete);
  };

  const [refresherIsEnabled, setRefresherIsEnabled] = useState(false);

  useIonViewDidEnter(() => setRefresherIsEnabled(true));
  useIonViewWillLeave(() => setRefresherIsEnabled(false));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Bestellingen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" disabled={!refresherIsEnabled} onIonRefresh={handleIonRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {!data && loading && <LoadingText />}
        {error && <ErrorText error={error} />}

        {data && <OrdersCard data={data} />}
        {data && <OrdersList data={data} />}
      </IonContent>
    </IonPage>
  );
};

interface OrdersCardProps {
  data: OrdersQueryData;
}

const OrdersCard = ({ data }: OrdersCardProps) => {
  return (
    <IonCard color="dark" className="ion-padding">
      <IonGrid>
        <IonRow className="ion-align-items-center" style={{ flexWrap: 'nowrap' }}>
          <IonCol size="auto" className="ion-margin-start">
            <IonChip color="light" style={{ height: 'auto' }} className="ion-no-margin">
              <IonIcon icon={basket} className="ion-no-margin" size="large" color="light"></IonIcon>
            </IonChip>
          </IonCol>
          <IonCol size="auto">
            <IonCardHeader>
              <IonCardSubtitle>Aantal bestellingen</IonCardSubtitle>
              <IonCardTitle>{data.orderCount}</IonCardTitle>
            </IonCardHeader>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

interface OrdersListProps {
  data: OrdersQueryData;
}

const OrdersList = ({ data }: OrdersListProps) => {
  return (
    <IonList className="ion-margin-bottom ion-padding-bottom">
      {data.orderWeeks.map((orderWeek) => (
        <OrdersItemGroup key={orderWeek.week} orderWeek={orderWeek} />
      ))}
    </IonList>
  );
};

interface OrdersItemGroupProps {
  orderWeek: OrderWeekData;
}

const OrdersItemGroup = ({ orderWeek }: OrdersItemGroupProps) => {
  return (
    <IonItemGroup>
      <IonItemDivider>
        <IonGrid>
          <IonRow>
            <IonCol>
              <strong>{orderWeek.week}</strong>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItemDivider>

      {orderWeek.orders.map((order) => (
        <OrdersItem order={order} key={`${order.__typename}-${order.id}`} />
      ))}

      {orderWeek.orders.length === 0 && (
        <IonItem lines="full">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel color="medium" className="ion-margin-vertical">
                  Geen bestellingen deze week.
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      )}
    </IonItemGroup>
  );
};

interface OrdersItemProps {
  order: ThuisbezorgdOrderData;
}

const OrdersItem = ({ order }: OrdersItemProps) => (
  <IonItem lines="full">
    <IonThumbnail slot="start" className="ion-margin">
      <img
        src={order.logoUrl}
        width="84"
        alt={order.restaurantId}
        style={{ objectFit: 'contain' }}
      />
    </IonThumbnail>
    <IonGrid>
      <IonRow>
        <IonCol size="6">
          <IonLabel>
            <h3>{order.name}</h3>
            <p>{format(new Date(order.orderedAt), 'PP', { locale: nl })}</p>
          </IonLabel>
        </IonCol>
        <IonCol size="6" className="ion-text-right">
          <OrdersPrice order={order} />
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonItem>
);

interface OrdersPriceProps {
  order: ThuisbezorgdOrderData;
}

const OrdersPrice = ({ order }: OrdersPriceProps) => {
  const parsedAmount = parseFloat(order.price);
  return <IonChip>€ {formatAmount(parsedAmount)}</IonChip>;
};

export default Orders;
