import React, { useState, useRef, useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonItem,
  IonList,
  IonModal,
  IonLabel,
  IonInput,
  IonTextarea,
} from '@ionic/react';
import { DatetimeChangeEventDetail } from '@ionic/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import ErrorToast from '../components/ErrorToast';
import SaveButton from '../components/SaveButton';
import DatetimePicker from '../components/DatetimePicker';
import serializeDate from '../utils/serializeDate';
import onEnter from '../utils/onEnter';
import reportValidity from '../utils/reportValidity';
import checkValidity from '../utils/checkValidity';

const SAVE_VOEDINGSCENTRUM_MEASUREMENT = gql`
  mutation saveVoedingscentrumMeasurementMutation(
    $createdDate: Date!
    $length: Int!
    $weight: Float!
    $comment: String
  ) {
    saveVoedingscentrumMeasurement(
      createdDate: $createdDate
      length: $length
      weight: $weight
      comment: $comment
    ) {
      id
      createdDate
      weight
      roundedBmi
      color
      comment
    }
  }
`;

export type ShowModal =
  | { createdDate: Date; length: number; weight?: number; comment?: string | null }
  | false;

interface MeasurementModalProps {
  pageRef: React.MutableRefObject<any>;
  showModal: ShowModal;
  setShowModal: (showModal: ShowModal) => void;
}

const initialState = {
  createdDate: new Date(),
  length: undefined,
  weight: undefined,
  comment: undefined,
};

const MeasurementModal = React.memo(
  ({ pageRef, showModal, setShowModal }: MeasurementModalProps) => {
    const modalRef = useRef<HTMLIonModalElement>(null);
    const [isValid, setIsValid] = useState(false);
    const [createdDate, setCreatedDate] = useState(initialState.createdDate);
    const [length, setLength] = useState<number | undefined>(initialState.length);
    const [weight, setWeight] = useState<number | undefined>(initialState.weight);
    const [comment, setComment] = useState<string | null | undefined>(initialState.comment);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
      if (showModal === false) {
        setCreatedDate(initialState.createdDate);
        setLength(initialState.length);
        setWeight(initialState.weight);
        setComment(initialState.comment);
      } else {
        setCreatedDate(showModal.createdDate);
        setLength(showModal.length);
        setWeight(showModal.weight);
        setComment(showModal.comment);
        setIsValid(!!(showModal.createdDate && showModal.length && showModal.weight));
      }
    }, [showModal]);

    const [saveVoedingscentrumMeasurement] = useMutation(SAVE_VOEDINGSCENTRUM_MEASUREMENT, {
      refetchQueries: ['voedingscentrumMeasurementsQuery'],
    });

    const handleModalDidDismiss = () => {
      setShowModal(false);
    };

    const handleDatetimeChange = (event: CustomEvent<DatetimeChangeEventDetail>) => {
      setCreatedDate(new Date(event.detail.value || ''));
    };

    const handleChange = async (event: CustomEvent<KeyboardEvent>) => {
      setIsValid(checkValidity(modalRef.current!));
    };

    const handleLengthInputChange = (event: CustomEvent<KeyboardEvent>) => {
      handleChange(event);
      // @ts-ignore
      setLength(parseInt(event.target.value || ''));
    };

    const handleWeightInputChange = (event: CustomEvent<KeyboardEvent>) => {
      handleChange(event);
      // @ts-ignore
      setWeight(parseFloat(event.target.value || ''));
    };

    const handleCommentInputChange = (event: CustomEvent<KeyboardEvent>) => {
      handleChange(event);
      // @ts-ignore
      setComment(event.target.value);
    };

    const handleFocus = async (event: CustomEvent<void>) => {
      const input = await (event.target as HTMLIonInputElement).getInputElement();
      input.select();
    };

    const handleErrorToastClose = () => {
      setErrorMessage('');
    };

    const handleEnter = onEnter(() => {
      reportValidity(modalRef.current!) && handleSaveButtonClick();
    });

    const handleSaveButtonClick = async () => {
      const variables = {
        createdDate: serializeDate(createdDate),
        length,
        weight,
        comment,
      };

      try {
        await saveVoedingscentrumMeasurement({ variables });
        setShowModal(false);
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.toString());
        }
      }
    };

    const touchDeviceProps = {
      swipeToClose: true,
      presentingElement: pageRef.current,
    };

    return (
      <IonModal
        ref={modalRef}
        isOpen={!!showModal}
        onDidDismiss={handleModalDidDismiss}
        {...('ontouchstart' in window ? touchDeviceProps : {})}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}>Annuleer</IonButton>
            </IonButtons>
            <IonTitle>Gewicht</IonTitle>
            <IonButtons slot="end">
              <SaveButton isValid={isValid} onClick={handleSaveButtonClick} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <ErrorToast
            isOpen={!!errorMessage}
            message={errorMessage}
            onClose={handleErrorToastClose}
          />

          <IonList>
            <IonItem>
              <IonLabel>Datum</IonLabel>
              <DatetimePicker date={createdDate} onIonChange={handleDatetimeChange} />
            </IonItem>

            <IonItem>
              <IonLabel>Lengte (cm)</IonLabel>
              <IonInput
                type="number"
                step="1"
                className="ion-text-right"
                required
                inputMode="numeric"
                value={length ? length.toString() : undefined}
                onIonInput={handleLengthInputChange}
                onIonFocus={handleFocus}
                onKeyPress={handleEnter}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel>Gewicht (kg)</IonLabel>
              <IonInput
                type="number"
                step=".1"
                className="ion-text-right"
                required
                inputMode="decimal"
                value={weight ? weight.toString() : undefined}
                onIonInput={handleWeightInputChange}
                onIonFocus={handleFocus}
                onKeyPress={handleEnter}
              ></IonInput>
            </IonItem>

            <IonItem color="light">
              <IonLabel position="stacked">Opmerking</IonLabel>
              <IonTextarea
                value={comment}
                onIonInput={handleCommentInputChange}
                autocapitalize="on"
                autoGrow
                rows={4}
              />
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    );
  }
);

export default MeasurementModal;
