import React from 'react';
import { IonButtons, IonBackButton } from '@ionic/react';
interface BackButtonProps {
  text?: string;
}

export const BackButton = ({ text }: BackButtonProps) => (
  <IonButtons slot="start">
    <IonBackButton defaultHref="/dashboard" text={text || 'Terug'} />
  </IonButtons>
);

export default BackButton;
