import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { hasSubscription } from "@jumpn/utils-graphql";
import { absintheSocketLink } from "./absinthe";

const httpLink = new HttpLink({ uri: "/graphql" });

const link = ApolloLink.split(
  operation => hasSubscription(operation.query),
  absintheSocketLink,
  httpLink
);

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link,
  cache
});
