import React from 'react';
import { IonContent, IonItem, IonInput, IonButton, IonLabel } from '@ionic/react';

const Login = () => {
  return (
    <IonContent className="ion-padding">
      <form className="ion-margin">
        <header className="ion-text-center ion-padding-vertical">
          <h3>Welkom bij My Nutrition</h3>
        </header>
        <main className="ion-padding-vertical">
          <IonItem lines="full">
            <IonLabel>E-mail</IonLabel>
            <IonInput
              name="email"
              type="email"
              placeholder="info@example.com"
              required
              autofocus
            ></IonInput>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>Wachtwoord</IonLabel>
            <IonInput name="password" type="password" placeholder="Wachtwoord" required></IonInput>
          </IonItem>
        </main>
        <footer className="ion-padding-vertical">
          <IonButton size="large" type="submit" expand="block">
            Log in
          </IonButton>
        </footer>
      </form>
    </IonContent>
  );
};

export default Login;
