import React from 'react';
import { IonDatetime } from '@ionic/react';
import { addYears } from 'date-fns';

interface DatetimePickerProps {
  date: Date;
  [key: string]: any;
}

const DatetimePicker = ({ date, ...props }: DatetimePickerProps) => (
  <IonDatetime
    displayFormat="DD-MM-YYYY"
    placeholder="Vandaag"
    max={addYears(date, 1).toISOString()}
    value={date.toISOString()}
    cancelText="Annuleer"
    doneText="Gereed"
    {...props}
  />
);

export default DatetimePicker;
