import React from 'react';
import { IonLabel, IonIcon, IonButton } from '@ionic/react';
import { add } from 'ionicons/icons';

interface AddButtonProps {
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void;
}

const AddButton = ({ onClick }: AddButtonProps) => (
  <IonButton onClick={onClick}>
    <IonIcon slot="icon-only" icon={add} />
    <IonLabel className="ion-hide-sm-down">Voeg toe</IonLabel>
  </IonButton>
);

export default AddButton;
