import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { ApolloLink } from "apollo-link";

let link = ApolloLink.from([]);

if (typeof window !== "undefined") {
  const websocketProtocol =
    window.location.protocol === "https:" ? "wss" : "ws";
  const websocketUrl = `${websocketProtocol}://${window.location.host}/socket`;

  const phoenixSocket = new PhoenixSocket(websocketUrl);
  const absintheSocket = AbsintheSocket.create(phoenixSocket);

  link = createAbsintheSocketLink(absintheSocket);
}

export const absintheSocketLink = link;
