import React from 'react';
import { IonText } from '@ionic/react';

const LoadingText = () => (
  <IonText className="ion-padding" color="medium" style={{ display: 'block' }}>
    Laden...
  </IonText>
);

export default LoadingText;
