import React from 'react';
import { IonApp, setupConfig, IonPage } from '@ionic/react';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './apollo/client';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';

import Tabs from './Tabs';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';

setupConfig({
  mode: 'ios',
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <IonApp>
          <IonPage>
            <Switch>
              <Route path="/login" component={Login}></Route>
              <Route path="/" component={Tabs}></Route>
            </Switch>
          </IonPage>
        </IonApp>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
