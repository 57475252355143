import { IonText } from '@ionic/react';
import React from 'react';

interface BMIProps {
  color?: string;
  roundedBmi: string;
  component?: React.ComponentType<{ color?: string }>;
}

const BMI = React.memo(({ roundedBmi, color, component }: BMIProps) => {
  const Component = component || IonText;

  return (
    <Component color={color}>
      {parseFloat(roundedBmi).toLocaleString(undefined, { minimumFractionDigits: 1 })}
    </Component>
  );
});

export default BMI;
