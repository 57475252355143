import React from 'react';
import { IonText } from '@ionic/react';

interface ErrorTextProps {
  error: Error;
}

const ErrorText = ({ error }: ErrorTextProps) => (
  <IonText className="ion-padding" color="danger" style={{ display: 'block' }}>
    {error.toString()}
  </IonText>
);

export default ErrorText;
