import React from 'react';
import { IonLabel, IonIcon, IonButton } from '@ionic/react';
import { createOutline } from 'ionicons/icons';

interface EditButtonProps {
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void;
}

const EditButton = ({ onClick }: EditButtonProps) => (
  <IonButton onClick={onClick}>
    <IonIcon slot="icon-only" icon={createOutline} />
    <IonLabel className="ion-hide-sm-down">Bewerk</IonLabel>
  </IonButton>
);

export default EditButton;
