import React from 'react';
import { IonButton } from '@ionic/react';

interface SaveButtonProps {
  isValid: boolean;
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void;
}

const SaveButton = ({ isValid, onClick }: SaveButtonProps) => (
  <IonButton disabled={!isValid} color={isValid ? 'primary' : 'dark'} onClick={onClick}>
    <strong>Bewaar</strong>
  </IonButton>
);

export default SaveButton;
