import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonItemDivider,
  IonText,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonButtons,
  useIonViewDidEnter,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import BackButton from '../../components/BackButton';
import { calculatorOutline, refreshOutline, walletOutline } from 'ionicons/icons';
import useLocalStorage from 'use-local-storage';
import { InputChangeEventDetail } from '@ionic/core';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';

type StringInputType = string | undefined;
type NumberInputType = number | undefined;

enum RiskType {
  Percentage = 'percentage',
  Fixed = 'fixed',
}

const PositionSizeCalculator = () => {
  useIonViewDidEnter(() => {
    document.title = 'Positiegrootte berekenen';
  });

  const defaultAccountSize = 500;
  const defaultRiskType: string = RiskType.Percentage;
  const defaultRiskPercentage = 5;
  const defaultRiskAmount = 25;
  const defaultMakerFee = -0.025;
  const defaultTakerFee = 0.075;
  const defaultEntryPrice = 1000;
  const defaultStopPrice = 975;
  const defaultTakeProfitPrice = 1050;

  const [accountSize, setAccountSize] = useLocalStorage<NumberInputType>(
    'accountSize',
    defaultAccountSize
  );
  const [riskType, setRiskType] = useLocalStorage<StringInputType>('riskType', defaultRiskType);
  const [riskPercentage, setRiskPercentage] = useLocalStorage<NumberInputType>(
    'riskPercentage',
    defaultRiskPercentage
  );
  const [riskAmount, setRiskAmount] = useLocalStorage<NumberInputType>(
    'riskAmount',
    defaultRiskAmount
  );
  const [makerFee, setMakerFee] = useLocalStorage<NumberInputType>('makerFee', defaultMakerFee);
  const [takerFee, setTakerFee] = useLocalStorage<NumberInputType>('takerFee', defaultTakerFee);
  const [entryPrice, setEntryPrice] = useLocalStorage<NumberInputType>(
    'entryPrice',
    defaultEntryPrice
  );
  const [stopPrice, setStopPrice] = useLocalStorage<NumberInputType>('stopPrice', defaultStopPrice);
  const [takeProfitPrice, setTakeProfitPrice] = useLocalStorage<NumberInputType>(
    'takeProfitPrice',
    defaultTakeProfitPrice
  );

  const makeChangeHandler =
    (callback: (value?: string) => void) => (event: CustomEvent<InputChangeEventDetail>) => {
      // @ts-ignore
      callback(event.target.value || undefined);
    };

  const makeNumericChangeHandler = (callback: (value?: number) => void) =>
    makeChangeHandler((value?: string) =>
      callback(value === undefined ? undefined : parseFloat(value))
    );

  const handleAccountSizeChange = makeNumericChangeHandler(setAccountSize);
  const handleRiskTypeChange = makeChangeHandler(setRiskType);
  const handleRiskPercentageChange = makeNumericChangeHandler(setRiskPercentage);
  const handleRiskAmountChange = makeNumericChangeHandler(setRiskAmount);
  const handleMakerFeeChange = makeNumericChangeHandler(setMakerFee);
  const handleTakerFeeChange = makeNumericChangeHandler(setTakerFee);
  const handleEntryPriceChange = makeNumericChangeHandler(setEntryPrice);
  const handleStopPriceChange = makeNumericChangeHandler(setStopPrice);
  const handleTakeProfitPriceChange = makeNumericChangeHandler(setTakeProfitPrice);

  const risk = calculateRisk(accountSize, riskType, riskPercentage, riskAmount);
  const leverage = calculateLeverage(accountSize, risk, entryPrice, stopPrice);

  const positionSize = calculatePositionSize(accountSize, leverage);
  const riskRewardRatio = calculateRiskRewardRatio(entryPrice, stopPrice, takeProfitPrice);
  const maxProfit = calculateMaxProfit(positionSize, entryPrice, takeProfitPrice, makerFee);
  const maxLoss = calculateMaxLoss(positionSize, entryPrice, stopPrice, makerFee, takerFee);

  const handleResetButtonClick = () => {
    setAccountSize(defaultAccountSize);
    setRiskType(defaultRiskType);
    setRiskPercentage(defaultRiskPercentage);
    setRiskAmount(defaultRiskAmount);
    setMakerFee(defaultMakerFee);
    setTakerFee(defaultTakerFee);
    setEntryPrice(defaultEntryPrice);
    setStopPrice(defaultStopPrice);
    setTakeProfitPrice(defaultTakeProfitPrice);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Positiegrootte berekenen</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleResetButtonClick}>
              <IonIcon slot="icon-only" icon={refreshOutline} />
              <IonLabel className="ion-hide-sm-down">Herstel</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <main style={{ minHeight: '100vh' }}>
          <IonList>
            <IonItemDivider>Account</IonItemDivider>

            <IonItem>
              <IonLabel>Accountgrootte</IonLabel>
              <IonInput
                type="number"
                step="1"
                inputMode="numeric"
                required
                value={accountSize}
                onIonChange={handleAccountSizeChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">$</IonNote>
            </IonItem>

            <IonItem>
              <IonSelect
                value={riskType}
                interface="action-sheet"
                onIonChange={handleRiskTypeChange}
                style={{ '--padding-start': 0 }}
              >
                <IonSelectOption value="percentage">Risicopercentage</IonSelectOption>
                <IonSelectOption value="fixed">Risicobedrag</IonSelectOption>
              </IonSelect>
              {riskType === RiskType.Percentage && (
                <IonInput
                  type="number"
                  step="1"
                  inputMode="numeric"
                  required
                  value={riskPercentage}
                  onIonChange={handleRiskPercentageChange}
                  className="ion-text-right"
                  style={{ fontFeatureSettings: '"kern", "tnum"' }}
                ></IonInput>
              )}
              {riskType === RiskType.Fixed && (
                <IonInput
                  type="number"
                  step="1"
                  inputMode="numeric"
                  required
                  value={riskAmount}
                  onIonChange={handleRiskAmountChange}
                  className="ion-text-right"
                  style={{ fontFeatureSettings: '"kern", "tnum"' }}
                ></IonInput>
              )}
              <IonNote slot="end">
                {riskType === RiskType.Percentage && '%'}
                {riskType === RiskType.Fixed && '$'}
              </IonNote>
            </IonItem>

            <IonItem>
              <IonLabel>
                <em>Maker fee</em>
              </IonLabel>
              <IonInput
                type="number"
                inputMode="numeric"
                step="0.001"
                required
                value={makerFee}
                onIonChange={handleMakerFeeChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">%</IonNote>
            </IonItem>

            <IonItem>
              <IonLabel>
                <em>Taker fee</em>
              </IonLabel>
              <IonInput
                type="number"
                inputMode="numeric"
                step="0.001"
                required
                value={takerFee}
                onIonChange={handleTakerFeeChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">%</IonNote>
            </IonItem>

            <IonItemDivider>Positie</IonItemDivider>

            <IonItem>
              <IonLabel>Stopprijs</IonLabel>
              <IonInput
                type="number"
                step="1"
                pattern="\d+"
                inputMode="numeric"
                required
                value={stopPrice}
                onIonChange={handleStopPriceChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">$</IonNote>
            </IonItem>

            <IonItem>
              <IonLabel>Instapprijs</IonLabel>
              <IonInput
                type="number"
                step="1"
                pattern="\d+"
                inputMode="numeric"
                required
                value={entryPrice}
                onIonChange={handleEntryPriceChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">$</IonNote>
            </IonItem>

            <IonItem>
              <IonLabel>Verkoopprijs</IonLabel>
              <IonInput
                type="number"
                step="1"
                pattern="\d+"
                inputMode="numeric"
                required
                value={takeProfitPrice}
                onIonChange={handleTakeProfitPriceChange}
                className="ion-text-right"
                style={{ fontFeatureSettings: '"kern", "tnum"' }}
              ></IonInput>
              <IonNote slot="end">$</IonNote>
            </IonItem>
          </IonList>

          <IonItemDivider className="ion-hide-sm-up">Resultaat</IonItemDivider>

          <PositionSizeCalculatorCard1
            leverage={leverage}
            positionSize={positionSize}
            accountSize={accountSize}
            riskRewardRatio={riskRewardRatio}
            className="ion-hide-sm-up"
            style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          />

          <PositionSizeCalculatorCard2
            accountSize={accountSize}
            maxLoss={maxLoss}
            maxProfit={maxProfit}
            className="ion-hide-sm-up"
            style={{ marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          />
        </main>

        <footer slot="fixed" style={{ left: 0, right: 0, bottom: 0 }} className="ion-hide-sm-down">
          <PositionSizeCalculatorCard1
            leverage={leverage}
            positionSize={positionSize}
            accountSize={accountSize}
            riskRewardRatio={riskRewardRatio}
            className="ion-padding-horizontal"
            style={{ margin: 0, borderRadius: 0 }}
          />
          <PositionSizeCalculatorCard2
            accountSize={accountSize}
            maxLoss={maxLoss}
            maxProfit={maxProfit}
            className="ion-padding-horizontal"
            style={{ margin: 0, borderRadius: 0 }}
          />
        </footer>
      </IonContent>
    </IonPage>
  );
};

interface PositionSizeCalculatorCard1Props {
  leverage?: number;
  positionSize?: number;
  accountSize?: number;
  riskRewardRatio?: number;
  className?: string;
  style?: IonicReactProps['style'];
}

const PositionSizeCalculatorCard1 = React.memo(
  ({
    leverage,
    positionSize,
    accountSize,
    riskRewardRatio,
    className,
    style,
  }: PositionSizeCalculatorCard1Props) => {
    return (
      <IonCard color="dark" className={className} style={style}>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding ion-align-self-center ion-hide-md-down" size="auto">
              <IonIcon icon={calculatorOutline} size="large" />
            </IonCol>
            <IonCol className="ion-padding">
              <IonCardSubtitle>
                <IonText color="medium">
                  <em>Leverage</em>
                </IonText>
              </IonCardSubtitle>
              <IonCardTitle className="ion-text-sm-sm-down">
                <IonText color={leverageColor(leverage)}>{formatLeverage(leverage)}</IonText>
              </IonCardTitle>
            </IonCol>
            <IonCol className="ion-padding">
              <IonCardSubtitle>
                <IonText color="medium">Positiegrootte</IonText>
              </IonCardSubtitle>
              <IonCardTitle>{formatPositionSize(positionSize)}</IonCardTitle>
            </IonCol>
            <IonCol className="ion-padding ion-hide-sm-up" size="6">
              <IonCardSubtitle>
                <IonText color="medium">Accountgrootte</IonText>
              </IonCardSubtitle>
              <IonCardTitle>{formatAccountSize(accountSize)}</IonCardTitle>
            </IonCol>
            <IonCol className="ion-padding">
              <IonCardSubtitle>
                <IonText color="medium">
                  <em>Risk-Reward Ratio</em>
                </IonText>
              </IonCardSubtitle>
              <IonCardTitle>
                <IonText color={riskRewardRatioColor(riskRewardRatio)}>
                  {formatRiskRewardRatio(riskRewardRatio)}
                </IonText>
              </IonCardTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    );
  }
);

interface PositionSizeCalculatorCard2Props {
  accountSize?: number;
  maxLoss?: number;
  maxProfit?: number;
  className?: string;
  style?: IonicReactProps['style'];
}

const PositionSizeCalculatorCard2 = React.memo(
  ({ accountSize, maxLoss, maxProfit, className, style }: PositionSizeCalculatorCard2Props) => {
    return (
      <IonCard color="warning" className={className} style={style}>
        <IonGrid>
          <IonRow>
            <IonCol
              className="ion-padding ion-align-self-center ion-align-self-center ion-hide-md-down"
              size="auto"
            >
              <IonIcon icon={walletOutline} size="large" />
            </IonCol>
            <IonCol className="ion-padding ion-hide-sm-down">
              <IonCardSubtitle>
                <IonText color="dark">Accountgrootte</IonText>
              </IonCardSubtitle>
              <IonCardTitle>{formatAccountSize(accountSize)}</IonCardTitle>
            </IonCol>
            <IonCol className="ion-padding">
              <IonCardSubtitle>
                <IonText color="dark">Bij verlies</IonText>
              </IonCardSubtitle>
              <IonCardTitle>{formatMaxLoss(maxLoss)}</IonCardTitle>
              <IonText>
                Inclusief <em>maker-</em> en <em>taker fee</em>
              </IonText>
            </IonCol>
            <IonCol className="ion-padding">
              <IonCardSubtitle>
                <IonText color="dark">Bij winst</IonText>
              </IonCardSubtitle>
              <IonCardTitle>{formatMaxProfit(maxProfit)}</IonCardTitle>
              <IonText>
                Inclusief <em>maker fees</em>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    );
  }
);

function calculatePositionSize(accountSize?: number, leverage?: number) {
  if (accountSize === undefined || leverage === undefined) {
    return undefined;
  }

  return accountSize * leverage;
}

function calculateRisk(
  accountSize?: number,
  riskType?: string,
  riskPercentage?: number,
  riskAmount?: number
) {
  if (
    accountSize === undefined ||
    riskType === undefined ||
    riskPercentage === undefined ||
    riskAmount === undefined
  ) {
    return undefined;
  }

  if (riskType === RiskType.Percentage) {
    return riskPercentage;
  }

  if (riskType === RiskType.Fixed) {
    return (riskAmount / accountSize) * 100;
  }

  return undefined;
}

function calculateLeverage(
  accountSize?: number,
  riskPercentage?: number,
  entryPrice?: number,
  stopPrice?: number
) {
  if (
    accountSize === undefined ||
    riskPercentage === undefined ||
    entryPrice === undefined ||
    stopPrice === undefined
  ) {
    return undefined;
  }

  const lossPercentage = ((entryPrice - stopPrice) / entryPrice) * 100;

  return Math.abs(riskPercentage / lossPercentage);
}

function calculateRiskRewardRatio(
  entryPrice?: number,
  stopPrice?: number,
  takeProfitPrice?: number
) {
  if (entryPrice === undefined || stopPrice === undefined || takeProfitPrice === undefined) {
    return undefined;
  }

  const rewardSize = takeProfitPrice - entryPrice;
  const riskSize = entryPrice - stopPrice;

  return rewardSize / riskSize;
}

function calculateMaxProfit(
  positionSize?: number,
  entryPrice?: number,
  takeProfitPrice?: number,
  makerFee?: number
) {
  if (
    positionSize === undefined ||
    entryPrice === undefined ||
    takeProfitPrice === undefined ||
    makerFee === undefined
  ) {
    return undefined;
  }

  const entryFee = (positionSize * makerFee) / 100;
  const exitFee = (positionSize * (takeProfitPrice / entryPrice) * makerFee) / 100;
  const profit = positionSize * (takeProfitPrice / entryPrice) - positionSize;

  return Math.abs(profit) - entryFee - exitFee;
}

function calculateMaxLoss(
  positionSize?: number,
  entryPrice?: number,
  stopPrice?: number,
  makerFee?: number,
  takerFee?: number
) {
  if (
    positionSize === undefined ||
    entryPrice === undefined ||
    stopPrice === undefined ||
    makerFee === undefined ||
    takerFee === undefined
  ) {
    return undefined;
  }

  const entryFee = (positionSize * makerFee) / 100;
  const exitFee = (positionSize * (stopPrice / entryPrice) * takerFee) / 100;
  const loss = positionSize * (stopPrice / entryPrice) - positionSize;

  return -Math.abs(loss) - entryFee - exitFee;
}

function leverageColor(leverage?: number) {
  if (leverage === undefined) {
    return undefined;
  }

  if (leverage >= 10) {
    return 'danger';
  }

  if (leverage >= 4) {
    return 'warning';
  }

  return 'success';
}

function riskRewardRatioColor(riskRewardRatio?: number) {
  if (riskRewardRatio === undefined) {
    return undefined;
  }

  if (riskRewardRatio < 1) {
    return 'danger';
  }

  if (riskRewardRatio < 2) {
    return 'warning';
  }

  if (riskRewardRatio >= 5) {
    return 'danger';
  }

  if (riskRewardRatio >= 4) {
    return 'warning';
  }

  return 'success';
}

function formatRiskRewardRatio(riskRewardRatio?: number) {
  if (riskRewardRatio === undefined) {
    return '–';
  }

  return riskRewardRatio.toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
}

function formatLeverage(leverage?: number) {
  if (leverage === undefined) {
    return '–';
  }

  return `${leverage.toLocaleString(undefined, { maximumFractionDigits: 2 })}x`;
}

function formatPositionSize(positionSize?: number) {
  if (positionSize === undefined) {
    return '–';
  }

  return `$ ${positionSize.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
}

function formatAccountSize(accountSize?: number) {
  if (accountSize === undefined) {
    return '-';
  }

  return `$ ${accountSize.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
}

function formatMaxProfit(maxProfit?: number) {
  if (maxProfit === undefined) {
    return '–';
  }

  return `$ ${maxProfit.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
}

function formatMaxLoss(maxLoss?: number) {
  if (maxLoss === undefined) {
    return '–';
  }

  return `$ ${maxLoss.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
}

export default PositionSizeCalculator;
