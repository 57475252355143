import React, { useState, useRef } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonButtons,
  useIonViewDidEnter,
} from '@ionic/react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import LoadingText from '../components/LoadingText';
import ErrorText from '../components/ErrorText';
import { nl } from 'date-fns/locale';
import BackButton from '../components/BackButton';
import { RouteComponentProps } from 'react-router';
import BMI from '../components/BMI';
import EditButton from '../components/EditButton';
import MeasurementModal, { ShowModal } from './MeasurementModal';

interface VoedingscentrumMeasurementData {
  voedingscentrumMeasurement: VoedingscentrumMeasurementData;
}

interface VoedingscentrumMeasurementData {
  id: string;
  createdDate: string;
  weight: string;
  length: string;
  roundedBmi: string;
  color: number;
  comment: string | null;
}

const VOEDINGSCENTRUM_MEASUREMENTS_QUERY = gql`
  query voedingscentrumMeasurementQuery($id: ID!) {
    voedingscentrumMeasurement(id: $id) {
      id
      createdDate
      weight
      length
      roundedBmi
      color
      comment
    }
  }
`;

type MeasurementProps = RouteComponentProps<{ id: string }>;

const Measurement = ({ match }: MeasurementProps) => {
  useIonViewDidEnter(() => {
    document.title = 'Gewicht';
  });

  const pageRef = useRef(undefined);
  const [showModal, setShowModal] = useState<ShowModal>(false);
  const [lastData, setLastData] = useState<VoedingscentrumMeasurementData | undefined>();

  const {
    loading,
    error,
    data: currentData,
  } = useQuery<VoedingscentrumMeasurementData>(VOEDINGSCENTRUM_MEASUREMENTS_QUERY, {
    onCompleted: setLastData,
    fetchPolicy: 'cache-and-network',
    variables: { id: match.params.id },
  });

  const data = loading ? lastData : currentData;

  const handleEditButtonClick = (event: React.MouseEvent<HTMLIonButtonElement>) => {
    if (data) {
      const { voedingscentrumMeasurement } = data;

      const createdDate = new Date(voedingscentrumMeasurement.createdDate);
      const length = parseInt(voedingscentrumMeasurement.length);
      const weight = parseFloat(voedingscentrumMeasurement.weight);
      const comment = voedingscentrumMeasurement.comment;

      setShowModal({ createdDate, length, weight, comment });
    }
  };

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Bekijk gewicht</IonTitle>
          <IonButtons slot="end">
            <EditButton onClick={handleEditButtonClick} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data && (
          <MeasurementModal pageRef={pageRef} showModal={showModal} setShowModal={setShowModal} />
        )}

        {!data && loading && <LoadingText />}
        {error && <ErrorText error={error} />}

        {data && <MeasurementCard data={data} />}
      </IonContent>
    </IonPage>
  );
};

interface MeasurementCardProps {
  data: VoedingscentrumMeasurementData;
}

const MeasurementCard = React.memo(({ data }: MeasurementCardProps) => {
  const measurement = data.voedingscentrumMeasurement;
  const date = new Date(measurement.createdDate);
  const formattedDate = format(date, 'PP', { locale: nl });

  return (
    <>
      <IonCard
        color="dark"
        className="ion-padding"
        style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-no-padding" size="12" sizeXs="4">
              <MeasurementCardItem title="Gewicht">
                <IonCardTitle>
                  {formatDecimal(measurement.weight)}{' '}
                  <IonText color="medium" class="small">
                    <small>kg</small>
                  </IonText>
                </IonCardTitle>
              </MeasurementCardItem>
            </IonCol>
            <IonCol className="ion-no-padding" size="12" sizeXs="4">
              <MeasurementCardItem title="Lengte">
                <IonCardTitle>
                  {formatDecimal(measurement.length)}{' '}
                  <IonText color="medium" class="small">
                    <small>cm</small>
                  </IonText>
                </IonCardTitle>
              </MeasurementCardItem>
            </IonCol>
            <IonCol className="ion-no-padding" size="12" sizeXs="4">
              <MeasurementCardItem title="BMI">
                <div className="ion-margin-vertical">
                  <BMI
                    roundedBmi={measurement.roundedBmi}
                    color={['success', 'warning', 'danger'][measurement.color]}
                    component={IonBadge}
                  />
                </div>
              </MeasurementCardItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>

      <IonCard
        color="primary"
        className="ion-padding"
        style={{ marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-no-padding" size="12" sizeXs="4">
              <MeasurementCardItem title="Datum">
                <div className="ion-margin-vertical">{formattedDate}</div>
              </MeasurementCardItem>
            </IonCol>
            <IonCol className="ion-no-padding" size="12" sizeXs="4">
              <MeasurementCardItem title="Opmerking">
                <div className="ion-margin-vertical">
                  {measurement.comment ? measurement.comment : <em>Leeg</em>}
                </div>
              </MeasurementCardItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </>
  );
});

interface MeasurementCardItemProps {
  title: string;
  children: React.ReactNode;
}

const MeasurementCardItem = ({ title, children }: MeasurementCardItemProps) => (
  <IonCardHeader>
    <IonCardSubtitle>{title}</IonCardSubtitle>
    {children}
  </IonCardHeader>
);

const formatDecimal = (number: string) =>
  parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 1 });

export default Measurement;
