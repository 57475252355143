import React from 'react';
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import { Route, Redirect } from 'react-router';
import Consumptions from './pages/Consumptions';
import More from './pages/More';
import ShoppingList from './pages/ShoppingList';
import Measurements from './pages/Measurements';
import Measurement from './pages/Measurement';
import PositionSizeCalculator from './pages/crypto/PositionSizeCalculator';
import Dashboard from './pages/Dashboard';
import { IonReactRouter } from '@ionic/react-router';
import Orders from './pages/Orders';
import Activities from './pages/Activities';

const Tabs = () => (
  <IonReactRouter>
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/" render={() => <Redirect to="/dashboard" />} exact />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/consumptions" component={Consumptions} />
        <Route path="/shopping-list" component={ShoppingList} />
        <Route path="/measurements" component={Measurements} />
        <Route path="/measurement/:id" component={Measurement} />
        <Route path="/crypto/position-size-calculator" component={PositionSizeCalculator} />
        <Route path="/activities" component={Activities} />
        <Route path="/orders" component={Orders} />
        <Route path="/more" component={More} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" translucent>
        <IonTabButton style={{ width: '100%' }} tab="dashboard" href="/dashboard">
          <TabButtonLabel>Dashboard</TabButtonLabel>
        </IonTabButton>

        <IonTabButton
          style={{ width: '100%' }}
          tab="consumptions"
          href="/consumptions"
          className="ion-hide-sm-down"
        >
          <TabButtonLabel>Voeding</TabButtonLabel>
        </IonTabButton>

        <IonTabButton style={{ width: '100%' }} tab="shopping-list" href="/shopping-list">
          <TabButtonLabel>Boodschappen</TabButtonLabel>
        </IonTabButton>

        <IonTabButton
          style={{ width: '100%' }}
          tab="measurements"
          href="/measurements"
          className="ion-hide-sm-down"
        >
          <TabButtonLabel>Gewicht</TabButtonLabel>
        </IonTabButton>

        <IonTabButton
          style={{ width: '100%' }}
          tab="position-size-calculator"
          href="/crypto/position-size-calculator"
          className="ion-hide-md-down"
        >
          <TabButtonLabel>Crypto</TabButtonLabel>
        </IonTabButton>

        <IonTabButton style={{ width: '100%' }} tab="more" href="/more">
          <IonIcon icon={ellipsisHorizontal} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </IonReactRouter>
);

interface TabButtonLabelProps {
  children: React.ReactNode;
}

const TabButtonLabel = ({ children }: TabButtonLabelProps) => (
  <IonLabel style={{ fontSize: 15 }}>{children}</IonLabel>
);

export default Tabs;
