import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonCol,
  IonRow,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonNote,
  IonListHeader,
  IonText,
  useIonViewWillLeave,
  useIonViewDidEnter,
} from '@ionic/react';
import React, { useState } from 'react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import AddButton from '../components/AddButton';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { RefresherEventDetail } from '@ionic/core';
import LoadingText from '../components/LoadingText';
import ErrorText from '../components/ErrorText';
import sumBy from 'lodash.sumby';
import { RouteComponentProps } from 'react-router';
import serializeDate from '../utils/serializeDate';
import { subDays, addDays } from 'date-fns/esm';
import { isToday, format } from 'date-fns';
import { nl } from 'date-fns/locale';
import upperFirst from 'lodash/upperFirst';
import BackButton from '../components/BackButton';

const VOEDINGSCENTRUM_CONSUMPTIONS_QUERY = gql`
  query voedingscentrumConsumptionsQuery($date: Date!) {
    voedingscentrumConsumptions(consumptionDate: $date, sortBy: ["asc:created_date"]) {
      id
      productUnitId
      productName
      brandName
      preparationMethodName
      amount
      unitName
      period
      energie
      vet
      verzadigdVet
      koolhydraten
      eiwit
      vezels
      zout
    }
  }
`;

const SYNC_VOEDINGSCENTRUM_CONSUMPTIONS_MUTATION = gql`
  mutation syncVoedingscentrumConsumptionsMutation($date: Date!) {
    syncVoedingscentrumConsumptions(consumptionDate: $date)
  }
`;

interface VoedingscentrumConsumptionData {
  id: string;
  productUnitId: string;
  productName: string;
  brandName: string;
  preparationMethodName: string;
  amount: string;
  unitName: string;
  period: number;
  energie: string;
  vet: string;
  verzadigdVet: string;
  koolhydraten: string;
  eiwit: string;
  vezels: string;
  zout: string;
}

interface VoedingscentrumConsumptionsData {
  voedingscentrumConsumptions: VoedingscentrumConsumptionData[];
}

type ConsumptionsProps = RouteComponentProps;

const Consumptions = (props: ConsumptionsProps) => {
  useIonViewDidEnter(() => {
    document.title = 'Voeding';
  });

  const params = new URLSearchParams(props.location.search);
  const date = new Date(params.get('date') || new Date());
  const formatDate = (date: Date, str: string) => upperFirst(format(date, str, { locale: nl }));
  const shortFormattedDate = formatDate(date, 'P');
  const formattedDate = formatDate(date, 'EEEE d MMMM');

  const [lastData, setLastData] = useState<VoedingscentrumConsumptionsData | undefined>();

  const {
    loading,
    error,
    data: nextData,
  } = useQuery<VoedingscentrumConsumptionsData>(VOEDINGSCENTRUM_CONSUMPTIONS_QUERY, {
    onCompleted: setLastData,
    fetchPolicy: 'cache-and-network',
    variables: { date: serializeDate(date) },
  });

  const [syncVoedingscentrumConsumptions] = useMutation<boolean>(
    SYNC_VOEDINGSCENTRUM_CONSUMPTIONS_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['voedingscentrumConsumptionsQuery'],
    }
  );

  const data = loading ? lastData : nextData;

  const handleIonRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));
    const variables = { date: serializeDate(date) };
    const mutation = syncVoedingscentrumConsumptions({ variables });
    Promise.all([delay, mutation]).finally(event.detail.complete);
  };

  const navigateToDate = (date: Date) => {
    props.history.push(`/consumptions?date=${serializeDate(date)}`, {
      direction: 'none',
    });
  };

  const handlePreviousButtonClick = () => navigateToDate(subDays(date, 1));
  const handleNextButtonClick = () => navigateToDate(addDays(date, 1));
  const handleDateClick = () => navigateToDate(new Date());

  const [refresherIsEnabled, setRefresherIsEnabled] = useState(false);

  useIonViewDidEnter(() => setRefresherIsEnabled(true));
  useIonViewWillLeave(() => setRefresherIsEnabled(false));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Voeding</IonTitle>
          <IonButtons slot="end">
            <AddButton />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handlePreviousButtonClick}>
              <IonIcon icon={arrowBack} />
              <IonLabel>Vorige</IonLabel>
            </IonButton>
          </IonButtons>
          <IonTitle title="Terug naar vandaag">
            {!isToday(date) && (
              <IonButton fill="clear" onClick={handleDateClick} title="Terug naar vandaag">
                <strong className="ion-hide-sm-up">{shortFormattedDate}</strong>
                <strong className="ion-hide-sm-down">{formattedDate}</strong>
              </IonButton>
            )}

            {isToday(date) && <IonLabel>Vandaag</IonLabel>}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleNextButtonClick}>
              <IonLabel>Volgende</IonLabel>
              <IonIcon icon={arrowForward} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" disabled={!refresherIsEnabled} onIonRefresh={handleIonRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {!data && loading && <LoadingText />}
        {error && <ErrorText error={error} />}

        <IonList>
          {data && <ConsumptionsHeader data={data} />}
          {data && data.voedingscentrumConsumptions.length === 0 && <ConsumptionsEmptyText />}
          {data && <ConsumptionsPeriodGroup period={1} title="Ochtend" data={data} />}
          {data && <ConsumptionsPeriodGroup period={2} title="Middag" data={data} />}
          {data && <ConsumptionsPeriodGroup period={3} title="Avond" data={data} />}
          {data && <ConsumptionsPeriodGroup period={4} title="Tussendoor" data={data} />}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

const ConsumptionsEmptyText = () => (
  <IonText className="ion-padding" color="medium" style={{ display: 'block' }}>
    Je hebt nog geen consumpties ingevoerd op deze dag.
  </IonText>
);

interface ConsumptionsHeaderProps {
  data: VoedingscentrumConsumptionsData;
}

const ConsumptionsHeader = ({ data }: ConsumptionsHeaderProps) => {
  const totalCalories = sumBy(data.voedingscentrumConsumptions, (o) => parseFloat(o.energie));
  const formattedTotalCalories = totalCalories.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });

  return (
    <IonListHeader color="primary" style={{ fontSize: 17, paddingLeft: 10, paddingRight: 10 }}>
      <IonGrid>
        <IonRow>
          <IonCol size="6">
            <strong>Energie</strong>
          </IonCol>
          <IonCol size="6" className="ion-text-right">
            <strong>{formattedTotalCalories} kcal</strong>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonListHeader>
  );
};

interface ConsumptionsPeriodItemProps {
  period: number;
  title: string;

  data: VoedingscentrumConsumptionsData;
}

const ConsumptionsPeriodGroup = ({ period, title, data }: ConsumptionsPeriodItemProps) => {
  const voedingscentrumConsumptions = data.voedingscentrumConsumptions.filter(
    (a) => a.period === period
  );

  if (voedingscentrumConsumptions.length === 0) {
    return null;
  }

  return (
    <IonItemGroup>
      <IonItemDivider style={{ fontSize: 15, '--padding-start': '10px' }}>
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <strong>{title}</strong>
            </IonCol>
            <IonCol size="3" className="ion-text-right">
              <strong>Aantal</strong>
            </IonCol>
            <IonCol size="3" className="ion-text-right">
              <strong>Energie</strong>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItemDivider>

      {voedingscentrumConsumptions.map((voedingscentrumConsumption) => (
        <ConsumptionsItem
          voedingscentrumConsumption={voedingscentrumConsumption}
          key={voedingscentrumConsumption.id}
        />
      ))}
    </IonItemGroup>
  );
};

const formatDecimal = (number: string) =>
  parseFloat(number).toLocaleString(undefined, { maximumFractionDigits: 0 });

interface ConsumptionsItemProps {
  voedingscentrumConsumption: VoedingscentrumConsumptionData;
}

const ConsumptionsItem = ({
  voedingscentrumConsumption: {
    productName,
    brandName,
    preparationMethodName,
    amount,
    unitName,
    energie,
  },
}: ConsumptionsItemProps) => {
  const handleItemClick = () => {};
  const handleDeleteClick = () => {};

  return (
    <IonItemSliding>
      <IonItem
        button
        detail={false}
        onClick={handleItemClick}
        style={{ fontSize: 15, '--padding-start': '10px' }}
        lines="full"
      >
        <IonGrid>
          <IonRow>
            <IonCol size="5">
              {productName}
              <IonNote class="ion-hide-sm-down"> · {brandName}</IonNote>
              {preparationMethodName !== 'N.v.t.' && (
                <IonNote class="ion-hide-sm-down"> · {preparationMethodName}</IonNote>
              )}
            </IonCol>
            <IonCol size="4" className="ion-text-right">
              {`${formatDecimal(amount)} ${unitName}`}
            </IonCol>
            <IonCol size="3" className="ion-text-right">
              {formatDecimal(energie)}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={handleDeleteClick}>
          Verwijder
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default Consumptions;
