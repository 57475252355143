import React from 'react';
import { IonHeader, IonToolbar, IonPage, IonTitle, IonContent, IonSearchbar } from '@ionic/react';
import BackButton from '../components/BackButton';

const Activities = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Activiteiten</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar
          animated
          showCancelButton="focus"
          placeholder="Zoek"
          cancelButtonText="Annuleer"
        ></IonSearchbar>
      </IonContent>
    </IonPage>
  );
};

export default Activities;
