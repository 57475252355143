import React, { useState, useEffect, useRef } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonList,
  IonToggle,
  IonCheckbox,
  IonText,
  IonRefresher,
  IonRefresherContent,
  IonModal,
  IonInput,
  IonNote,
  useIonViewDidEnter,
  useIonViewWillLeave,
  IonSegment,
  IonSegmentButton,
  IonTextarea,
} from '@ionic/react';
import { RefresherEventDetail, SegmentChangeEventDetail } from '@ionic/core';
import { informationCircleOutline, add, alertCircleOutline, basketOutline } from 'ionicons/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AddButton from '../components/AddButton';
import SaveButton from '../components/SaveButton';
import LoadingText from '../components/LoadingText';
import ErrorText from '../components/ErrorText';
import ErrorToast from '../components/ErrorToast';
import onEnter from '../utils/onEnter';
import reportValidity from '../utils/reportValidity';
import checkValidity from '../utils/checkValidity';
import BackButton from '../components/BackButton';

interface ShoppingListData {
  shoppingListItemsCount: number;
  shoppingListItems: ShoppingListItemData[];
}

interface ShoppingListVariables {
  isCompleted?: boolean;
  searchQuery?: string;
}

interface ShoppingListItemData {
  id: string;
  name: string;
  description: string;
  isCompleted: boolean;
  priority: number;
}

enum ShoppingListItemPriority {
  Low = 0,
  Normal = 1,
  High = 2,
}

const SHOPPING_LIST_QUERY = gql`
  query shoppingListQuery($isCompleted: Boolean) {
    shoppingListItemsCount: shoppingListItemsCount(isCompleted: $isCompleted)

    shoppingListItems(isCompleted: $isCompleted, sortBy: ["asc:is_completed", "desc:inserted_at"]) {
      id
      name
      description
      isCompleted
      priority
    }
  }
`;

type ShowModal =
  | { name: string; description: string; priority: number } // New
  | { id: string; name: string; description: string; priority: number } // Edit
  | false;

const newShoppingListItem = {
  name: '',
  description: '',
  priority: ShoppingListItemPriority.Normal,
};

const ShoppingList: React.FC = () => {
  useIonViewDidEnter(() => {
    document.title = 'Boodschappenlijst';
  });

  const pageRef = useRef(null);
  const searchbarRef = useRef<HTMLIonSearchbarElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [showModal, setShowModal] = useState<ShowModal>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [lastData, setLastData] = useState<ShoppingListData | undefined>();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    loading,
    error,
    data: currentData,
    refetch,
  } = useQuery<ShoppingListData, ShoppingListVariables>(SHOPPING_LIST_QUERY, {
    variables: {
      ...(showCompleted ? {} : { isCompleted: false }),
    },
    onCompleted: setLastData,
    fetchPolicy: 'cache-and-network',
  });

  const [createShoppingListItem] = useMutation(CREATE_SHOPPING_LIST_ITEM, {
    refetchQueries: ['shoppingListQuery'],
  });

  const data = loading ? lastData : currentData;

  const handleIonRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));
    Promise.all([refetch(), delay]).finally(event.detail.complete);
  };

  const handleErrorToastClose = () => {
    setErrorMessage('');
  };

  const handleCreate = async () => {
    if (!searchQuery) {
      return;
    }

    searchbarRef.current!.setFocus();

    let priority = 1;

    if (searchQuery.includes('!')) {
      priority = 2;
    } else if (searchQuery.includes('?')) {
      priority = 0;
    }

    try {
      const variables = { name: searchQuery, priority };
      await createShoppingListItem({ variables });
      setSearchQuery('');
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.toString());
      }
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleCreate();
  };

  const handleIonCancel = () => {
    handleCreate();
  };

  const handleIonInput = (event: CustomEvent<KeyboardEvent>) => {
    setSearchQuery((event.target as HTMLIonSearchbarElement).value || '');
  };

  const [refresherIsEnabled, setRefresherIsEnabled] = useState(false);

  useIonViewDidEnter(() => setRefresherIsEnabled(true));
  useIonViewWillLeave(() => setRefresherIsEnabled(false));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <BackButton />
          <IonTitle>Boodschappenlijst</IonTitle>
          <IonButtons slot="end">
            <AddButton onClick={() => setShowModal(newShoppingListItem)} />
          </IonButtons>
        </IonToolbar>
        <form action="" ref={formRef} onSubmit={handleSubmit}>
          <IonSearchbar
            type="text"
            ref={searchbarRef}
            animated
            showCancelButton={searchQuery ? 'always' : 'focus'}
            placeholder="Voeg toe"
            cancelButtonText={searchQuery ? 'Voeg toe' : 'Annuleer'}
            onIonCancel={handleIonCancel}
            value={searchQuery}
            onIonInput={handleIonInput}
            autocorrect="on"
            autoCapitalize="on"
            searchIcon={add}
          ></IonSearchbar>
        </form>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" disabled={!refresherIsEnabled} onIonRefresh={handleIonRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {data && (
          <ShoppingListItemModal
            pageRef={pageRef}
            showModal={showModal}
            setShowModal={setShowModal}
            setErrorMessage={setErrorMessage}
          />
        )}

        <ErrorToast
          isOpen={!!errorMessage}
          message={errorMessage}
          onClose={handleErrorToastClose}
        />

        {!data && loading && <LoadingText />}
        {error && <ErrorText error={error} />}

        {data && (
          <ShoppingListList
            {...{
              data,
              showCompleted,
              setShowCompleted,
              setShowModal,
              setErrorMessage,
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

interface ShoppingListListProps {
  data: ShoppingListData;
  showCompleted: boolean;
  setShowCompleted: (showCompleted: boolean) => void;
  setShowModal: (showModal: ShowModal) => void;
  setErrorMessage: (errorMessage: string) => void;
}

const ShoppingListList = ({
  data,
  showCompleted,
  setShowCompleted,
  setShowModal,
}: ShoppingListListProps) => {
  return (
    <IonList>
      <IonItem key="header">
        <IonText>
          <strong>{data.shoppingListItemsCount} items</strong>
        </IonText>
        <IonLabel slot="end" className="ion-text-right">
          Toon voltooid
        </IonLabel>
        <IonToggle
          onClick={() => setShowCompleted(!showCompleted)}
          checked={showCompleted}
          slot="end"
        />
      </IonItem>

      {data.shoppingListItems.map((shoppingListItem) => (
        <ShoppingListItem
          // Fixes rerender issue...
          key={`${shoppingListItem.id}-${shoppingListItem.priority}`}
          shoppingListItem={shoppingListItem}
          showCompleted={showCompleted}
          setShowModal={setShowModal}
        />
      ))}
    </IonList>
  );
};

const TOGGLE_SHOPPING_LIST_ITEM_MUTATION = gql`
  mutation toggleShoppingListItemMutation($id: ID!) {
    toggleShoppingListItem(id: $id) {
      id
      isCompleted
    }
  }
`;

const DELETE_SHOPPING_LIST_ITEM_MUTATION = gql`
  mutation deleteShoppingListItemMutation($id: ID!) {
    deleteShoppingListItem(id: $id)
  }
`;

interface ShoppingListItemProps {
  shoppingListItem: ShoppingListItemData;
  showCompleted: boolean;
  setShowModal: (showModal: ShowModal) => void;
}

const ShoppingListItem = React.memo(
  ({ shoppingListItem, showCompleted, setShowModal }: ShoppingListItemProps) => {
    const [toggleShoppingListItem] = useMutation(TOGGLE_SHOPPING_LIST_ITEM_MUTATION, {
      update: (proxy, mutationResult) => {
        const variables = {
          ...(showCompleted ? {} : { isCompleted: false }),
        };

        const data = proxy.readQuery<ShoppingListData>({
          query: SHOPPING_LIST_QUERY,
          variables,
        });

        if (data === null || showCompleted) {
          return;
        }

        const shoppingListItemsCount =
          data.shoppingListItemsCount +
          (mutationResult.data.toggleShoppingListItem.isCompleted ? -1 : 1);

        proxy.writeQuery({
          query: SHOPPING_LIST_QUERY,
          variables,
          data: { ...data, shoppingListItemsCount },
        });
      },
    });

    const [deleteShoppingListItem] = useMutation(DELETE_SHOPPING_LIST_ITEM_MUTATION, {
      refetchQueries: ['shoppingListQuery'],
    });

    const handleItemClick = () =>
      toggleShoppingListItem({
        variables: { id: shoppingListItem.id },
      });

    const handleInformationCircleClick = (event: React.MouseEvent<HTMLIonButtonElement>) => {
      event.stopPropagation();
      setShowModal(shoppingListItem);
    };

    const handleDeleteClick = () => {
      deleteShoppingListItem({
        variables: { id: shoppingListItem.id },
      });
    };

    const isCompletedStyle = shoppingListItem.isCompleted ? { textDecoration: 'line-through' } : {};

    return (
      <IonItemSliding>
        <IonItem button detail={false} onClick={handleItemClick}>
          <IonCheckbox slot="start" checked={shoppingListItem.isCompleted} />
          {shoppingListItem.priority === ShoppingListItemPriority.Low && (
            <IonIcon icon={basketOutline} color="medium" slot="start" />
          )}
          {shoppingListItem.priority === ShoppingListItemPriority.High && (
            <IonIcon icon={alertCircleOutline} color="danger" slot="start" />
          )}
          <IonLabel
            {...(shoppingListItem.priority === ShoppingListItemPriority.Low
              ? { color: 'medium', style: isCompletedStyle }
              : { style: isCompletedStyle })}
            {...(shoppingListItem.priority === ShoppingListItemPriority.High
              ? { color: 'danger', style: { fontWeight: '600', ...isCompletedStyle } }
              : { style: isCompletedStyle })}
          >
            {shoppingListItem.name}

            {shoppingListItem.description && (
              <IonNote style={isCompletedStyle}>
                {' · '}
                {shoppingListItem.description}
              </IonNote>
            )}
          </IonLabel>

          <IonButtons>
            <IonButton slot="end" onClick={handleInformationCircleClick}>
              <IonIcon icon={informationCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonItem>

        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={handleDeleteClick}>
            Verwijder
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    );
  }
);

const CREATE_SHOPPING_LIST_ITEM = gql`
  mutation createShoppingListItemMutation($name: String!, $description: String, $priority: Int) {
    createShoppingListItem(name: $name, description: $description, priority: $priority) {
      id
      name
      description
      isCompleted
      priority
    }
  }
`;

const UPDATE_SHOPPING_LIST_ITEM = gql`
  mutation updateShoppingListItemMutation(
    $id: ID!
    $name: String!
    $description: String
    $priority: Int
  ) {
    updateShoppingListItem(id: $id, name: $name, description: $description, priority: $priority) {
      id
      name
      description
      isCompleted
      priority
    }
  }
`;

interface ShoppingListItemModalProps {
  pageRef: React.MutableRefObject<any>;
  showModal: ShowModal;
  setShowModal: (showModal: ShowModal) => void;
  setErrorMessage: (errorMessage: string) => void;
}

const initialState = { name: '', description: '', priority: ShoppingListItemPriority.Normal };

const ShoppingListItemModal = React.memo(
  ({ pageRef, showModal, setShowModal, setErrorMessage }: ShoppingListItemModalProps) => {
    const modalRef = useRef<HTMLIonModalElement>(null);
    const [isValid, setIsValid] = useState(false);
    const [name, setName] = useState(initialState.name);
    const [priority, setPriority] = useState(initialState.priority);
    const [description, setDescription] = useState(initialState.description);

    useEffect(() => {
      if (showModal === false) {
        setName(initialState.name);
        setPriority(initialState.priority);
        setDescription(initialState.description);
      } else {
        setName(showModal.name);
        setPriority(showModal.priority);
        setDescription(showModal.description);
        setIsValid(true);
      }
    }, [showModal]);

    const [createShoppingListItem] = useMutation(CREATE_SHOPPING_LIST_ITEM, {
      refetchQueries: ['shoppingListQuery'],
    });

    const [updateShoppingListItem] = useMutation(UPDATE_SHOPPING_LIST_ITEM, {
      refetchQueries: ['shoppingListQuery'],
    });

    const handleModalDidDismiss = () => {
      setShowModal(false);
    };

    const handleChange = (_event: CustomEvent) => {
      setIsValid(checkValidity(modalRef.current!));
    };

    const handleNameInputChange = (event: CustomEvent<KeyboardEvent>) => {
      handleChange(event);
      // @ts-ignore
      setName(event.target.value || '');
    };

    const handlePrioritySegmentChange = (event: CustomEvent<SegmentChangeEventDetail>) => {
      handleChange(event);
      setPriority(parseInt(event.detail.value || '1'));
    };

    const handleDescriptionInputChange = (event: CustomEvent<KeyboardEvent>) => {
      handleChange(event);
      // @ts-ignore
      setDescription((event.target as HTMLIonInputElement).value || '');
    };

    const handleEnter = onEnter(() => {
      reportValidity(modalRef.current!) && handleSaveButtonClick();
    });

    const handleSaveButtonClick = async () => {
      const variables = { name, description, priority };

      try {
        if (showModal && 'id' in showModal) {
          await updateShoppingListItem({ variables: { id: showModal.id, ...variables } });
        } else if (showModal) {
          await createShoppingListItem({ variables });
        }

        setShowModal(false);
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.toString());
        }
      }
    };

    const touchDeviceProps = {
      swipeToClose: true,
      presentingElement: pageRef.current,
    };

    return (
      <IonModal
        ref={modalRef}
        isOpen={!!showModal}
        onDidDismiss={handleModalDidDismiss}
        {...('ontouchstart' in window ? touchDeviceProps : {})}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}>Annuleer</IonButton>
            </IonButtons>
            <IonTitle>{showModal && showModal.name ? showModal.name : 'Nieuw item'}</IonTitle>
            <IonButtons slot="end">
              <SaveButton isValid={isValid} onClick={handleSaveButtonClick} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList lines="full">
            <IonItem>
              <IonLabel position="fixed">Naam</IonLabel>
              <IonInput
                type="text"
                required
                value={name}
                onIonInput={handleNameInputChange}
                onKeyPress={handleEnter}
                autocorrect="on"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="fixed">Prioriteit</IonLabel>

              <IonSegment value={priority.toString()} onIonChange={handlePrioritySegmentChange}>
                <IonSegmentButton value={ShoppingListItemPriority.Normal.toString()}>
                  <IonLabel>Normaal</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value={ShoppingListItemPriority.Low.toString()}
                  layout="icon-start"
                >
                  <IonIcon icon={basketOutline} color="medium" />
                  <IonLabel color="medium"> Laag</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value={ShoppingListItemPriority.High.toString()}
                  layout="icon-start"
                >
                  <IonIcon icon={alertCircleOutline} color="danger" />
                  <IonLabel color="danger"> Hoog</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>

            <IonItem color="light">
              <IonLabel position="stacked">Omschrijving</IonLabel>
              <IonTextarea
                value={description}
                onIonInput={handleDescriptionInputChange}
                autocapitalize="on"
                autoGrow
                rows={4}
              />
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    );
  }
);

export default ShoppingList;
