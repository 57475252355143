import React from 'react';
import { IonToast } from '@ionic/react';

interface ErrorToastProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
}

const ErrorToast = ({ isOpen, message, onClose }: ErrorToastProps) => {
  const closeButton = {
    text: 'Sluiten',
    role: 'cancel',
    handler: onClose,
  };

  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={onClose}
      message={message}
      color="danger"
      buttons={[closeButton]}
    />
  );
};

export default ErrorToast;
