import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonCardSubtitle,
  IonChip,
  IonItem,
  useIonViewDidEnter,
} from '@ionic/react';
import { arrowForward, list, body, nutrition, rocket } from 'ionicons/icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

interface DashboardData {
  shoppingListItemsCount: number;
  savingsTotalAmount: string;
  spendingsTotalAmount: string;
}

const DASHBOARD_QUERY = gql`
  query dashboardQuery {
    shoppingListItemsCount(isCompleted: false)
    savingsTotalAmount
    spendingsTotalAmount
  }
`;

const Dashboard: React.FC = () => {
  const { data } = useQuery<DashboardData>(DASHBOARD_QUERY, { fetchPolicy: 'cache-and-network' });

  const nutritionSubtitle = format(new Date(), 'MMMM, yyyy', { locale: nl });
  const measurementsSubtitle = format(new Date(), 'MMMM, yyyy', { locale: nl });
  const shoppingListSubtitle = `Bekijk ${(data && data.shoppingListItemsCount) || ''} items`;

  useIonViewDidEnter(() => {
    document.title = 'Dashboard';
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <DashboardItem
          title="Voeding"
          subtitle={nutritionSubtitle}
          icon={nutrition}
          color="brand"
          link="/consumptions"
        />
        <DashboardItem
          title="Boodschappenlijst"
          subtitle={shoppingListSubtitle}
          icon={list}
          color="primary"
          link="/shopping-list"
        />
        <DashboardItem
          title="Gewicht"
          subtitle={measurementsSubtitle}
          icon={body}
          color="tertiary"
          link="/measurements"
        />

        <DashboardItem
          title="Crypto"
          subtitle="Bereken positiegrootte"
          icon={rocket}
          color="warning"
          link="/crypto/position-size-calculator"
        />
      </IonContent>
    </IonPage>
  );
};

interface DashboardItemProps {
  title: string;
  subtitle: string;
  icon: string;
  color: string;
  link: string;
}

const DashboardItem = ({ title, subtitle, icon, color, link }: DashboardItemProps) => (
  <>
    <IonCard routerLink={link} routerDirection="none" className="ion-hide-sm-down">
      <IonGrid>
        <IonRow className="ion-align-items-center" style={{ flexWrap: 'nowrap' }}>
          <IonCol size="auto" className="ion-margin-start">
            <IonChip color={color} style={{ height: 'auto' }}>
              <IonIcon icon={icon} className="ion-no-margin" size="large" color={color}></IonIcon>
            </IonChip>
          </IonCol>
          <IonCol size="auto">
            <IonCardHeader>
              <IonCardSubtitle>{subtitle}</IonCardSubtitle>
              <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>
          </IonCol>
          <IonCol className="ion-text-right ion-padding">
            <IonIcon icon={arrowForward} size="large" />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
    <IonItem
      lines="full"
      detail={false}
      routerLink={link}
      routerDirection="none"
      className="ion-hide-sm-up"
    >
      <IonChip color={color} style={{ height: 'auto' }} slot="start" className="ion-no-margin">
        <IonIcon icon={icon} className="ion-no-margin" size="large" color={color}></IonIcon>
      </IonChip>
      <IonCardHeader className="ion-margin-vertical ion-padding" style={{ overflow: 'hidden' }}>
        <IonCardSubtitle>{subtitle}</IonCardSubtitle>
        <IonCardTitle
          style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {title}
        </IonCardTitle>
      </IonCardHeader>
      <IonIcon icon={arrowForward} size="large" slot="end" color="medium" />
    </IonItem>
  </>
);

export default Dashboard;
